@import url(https://rsms.me/inter/inter.css);
.pmndrs-menu {
  font-family: 'Inter var', sans-serif;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  color: #808080;
  padding: 40px;
  pointer-events: none;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: row;
  font-size: 10px;
  line-height: 1.5em;
}

.pmndrs-menu > div {
  word-wrap: none;
  word-break: none;
  white-space: pre;
  padding-left: 25px;
  padding-right: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.pmndrs-menu > div b {
  font-weight: 600;
  color: #b0b0b0;
}

.pmndrs-menu a {
  pointer-events: all;
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

.pmndrs-menu a:hover {
  text-decoration: underline;
  color: inherit;
}

